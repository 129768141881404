@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

html {
  height: 100%;
}

body {
  background-color: #141414;
  color: #e8e6e3;
  min-height: 100vh;
  margin: 0;
  font-family: 'Roboto', 'segoe ui', 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #e8e6e3;
  text-decoration: none;
}

.button {
  box-shadow: 0 5px #999;
  cursor: pointer;
  outline: none;
}

button:hover {
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.123);
}

.btn:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}
