.container {
    padding-left: 15%;
    padding-right: 15%;
}

.content {
    padding-top: 15% !important;
}

.text {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: large;
} 

.arrow-btn {
    border: 0;
    background-color: transparent;
}

.arrow-btns-container {
    align-items: flex-end;
    padding-left: 0% !important;
    padding-right: 0% !important;
    display: flex;
    justify-content: space-between;
}

.arrow-btn-wrapper {
    display: contents;
    width: fit-content;
    width: -moz-fit-content
}

.arrow-btn-right {
    margin-left: auto;
}

.social-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    padding-bottom: 1em;
}

.text-card {
    padding: 1rem;
    border-radius: 1.5rem;
    border-color: rgba(22, 22, 22, 0.66);
    background-color: rgba(22, 22, 22, 0.66)
}

@media (min-width: 641px) {
    .arrow-btns-container {
        width: 30%;
        align-items: flex-end;
        padding-left: 15%;
        padding-right: 15%;
        display: flex;
        justify-content: space-between;
    }

    .text {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 400;
        margin-bottom: 5px;
        font-size: larger;
    }
}

@media (max-width: 640px) {
    .container {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}